import { TextField } from "@fluentui/react";
import React, { useCallback } from "react";
import { IncomeBySlicePieChart } from "./IncomeBySlicePieChart";
import { IncomeGrid } from "./IncomeGrid";
import { IncomeTimelineChart } from "./IncomeTimelineChart";
import { useIncomes } from "./UseIncomes";

interface IncomeViewerProps {
  className?: string;
}

export const IncomeViewer = ({ className }: IncomeViewerProps) => {
  const { incomeSource, saveIncomeSource, incomes, saveIncome } = useIncomes();
  const onIncomeSourceChange = useCallback(
    (_ev: React.FormEvent, newValue?: string) => {
      newValue && saveIncomeSource(newValue);
    },
    [saveIncomeSource],
  );
  return (
    <div className={className}>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <TextField
          label="Source Url"
          value={incomeSource}
          styles={{
            root: { flex: "auto 1 1" },
            fieldGroup: { flex: "auto 1 1", marginLeft: 5 },
            wrapper: { display: "flex", marginRight: 5 },
          }}
          onChange={onIncomeSourceChange}
        />
      </div>
      <IncomeTimelineChart incomes={incomes} />
      <IncomeBySlicePieChart incomes={incomes} />
      <IncomeGrid incomes={incomes} saveIncome={saveIncome} />
    </div>
  );
};
