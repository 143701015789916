import { FunctionComponent } from "react";
import "./App.css";
import { EventsCard } from "./Events";
import { IncomeViewer } from "./IncomeViewer";

const App: FunctionComponent = () => {
  return (
    <div className="App">
      <header className="App-header">Home</header>
      <div className="App-body">
        <EventsCard className="App-card" />
        <IncomeViewer className="App-card" />
      </div>
    </div>
  );
};

export default App;
