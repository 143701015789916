import { InteractiveBrowserCredential } from "@azure/identity";
import { BlobServiceClient } from "@azure/storage-blob";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CalendarEvent } from "./CalendarEvent";

export const useCalendarEvents = (todaysEventsOnly: boolean) => {
  const [loadingCalendarEvents, setLoadingCalendarEvents] = useState(true);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [nonce, setNonce] = useState(0);

  useEffect(() => {
    setLoadingCalendarEvents(true);
    getEvents()
      .then((response) => {
        setEvents(response.events);
      })
      .finally(() => {
        setLoadingCalendarEvents(false);
      });
  }, [nonce]);

  const calendarEvents = useMemo(() => {
    if (!todaysEventsOnly) {
      return events;
    }

    const now = new Date();

    return events.filter((c) => {
      const startTime = new Date(c.startTime);
      if (c.recurrenceType === 1) {
        startTime.setFullYear(now.getFullYear());
      }
      const endTime = new Date(startTime.getTime() + c.duration);
      return now >= startTime && now <= endTime;
    });
  }, [events, todaysEventsOnly]);

  const addCalendarEvent = useCallback(
    async (newEvent: CalendarEvent) => {
      await saveEvents(events.concat([newEvent]));
      setNonce(Date.now());
    },
    [events]
  );

  return { loadingCalendarEvents, calendarEvents, addCalendarEvent };
};

async function getEvents() {
  const blobClient = getBlobClient();
  const blobResponse = await blobClient.download();
  const blobBody = await blobResponse.blobBody;
  const json = await blobBody?.text();
  const result = JSON.parse(json || "{}") as { events: CalendarEvent[] };
  if (!result || !Array.isArray(result.events)) {
    return { events: [] };
  }
  return result;
}

async function saveEvents(events: CalendarEvent[]) {
  const blobClient = getBlobClient();
  const contents = JSON.stringify({ events }, null, 2);
  await blobClient.upload(contents, contents.length, {
    blobHTTPHeaders: { blobContentType: "application/json" },
  });
}

function getBlobClient() {
  const blobServiceClient = new BlobServiceClient(
    `https://alvarodocs.blob.core.windows.net`,
    new InteractiveBrowserCredential({
      clientId: "bea38e40-7474-4e61-b3fc-65f1b8704235",
      tenantId: "ba0b4428-d269-4721-bc80-b3c07ce4c2ff",
      redirectUri: window.location.href,
    })
  );
  const containerClient = blobServiceClient.getContainerClient("eventsdata");
  const blobClient = containerClient.getBlockBlobClient("events.json");
  return blobClient;
}
