import {
  CheckboxVisibility,
  DetailsList,
  Dropdown,
  IColumn,
  IDropdownOption,
} from "@fluentui/react";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { usDollarFormat } from "./Formats";
import { IncomeField } from "./IncomeField";
import { IncomeInfo, SaveIncomeCallback } from "./IncomeInfo";

const useColumns = (saveIncome: SaveIncomeCallback): IColumn[] => {
  return useMemo(
    () => [
      {
        key: "incomeName",
        fieldName: "incomeName",
        name: "Name",
        minWidth: 100,
        onRender: (item: IncomeInfo) => {
          return <IncomeField income={item} saveIncome={saveIncome} />;
        },
      },
      {
        key: "provider",
        fieldName: "provider",
        name: "Provider",
        minWidth: 100,
      },
      { key: "type", fieldName: "type", name: "Type", minWidth: 100 },
      {
        key: "date",
        fieldName: "date",
        name: "Date",
        onRender: (item: IncomeInfo) => item.date.toLocaleDateString(),
        minWidth: 80,
      },
      {
        key: "principal",
        fieldName: "principal",
        name: "Principal",
        onRender: (item: IncomeInfo) => (
          <div style={{ textAlign: "right" }}>
            {usDollarFormat.format(item.principal || item.amount)}
          </div>
        ),
        minWidth: 80,
      },
      {
        key: "amount",
        fieldName: "amount",
        name: "Amount",
        onRender: (item: IncomeInfo) => (
          <div style={{ textAlign: "right" }}>
            {usDollarFormat.format(item.amount)}
          </div>
        ),
        minWidth: 80,
      },
    ],
    [saveIncome],
  );
};

interface IncomeGridProps {
  incomes: IncomeInfo[];
  saveIncome: SaveIncomeCallback;
}

export const IncomeGrid = ({ incomes, saveIncome }: IncomeGridProps) => {
  const [selectedProvider, setSelectedProvider] = useState("All");
  const filteredIncomes = useMemo(
    () =>
      _.sortBy(
        incomes.filter(
          (c) => selectedProvider === "All" || selectedProvider === c.provider,
        ),
        (item) => item.date,
      ),
    [incomes, selectedProvider],
  );
  const principalValue = useMemo(() => {
    return usDollarFormat.format(
      filteredIncomes.reduce((total, current) => total + current.principal, 0),
    );
  }, [filteredIncomes]);
  const amountValue = useMemo(() => {
    return usDollarFormat.format(
      filteredIncomes.reduce((total, current) => total + current.amount, 0),
    );
  }, [filteredIncomes]);
  const providers = useMemo(
    () =>
      [{ key: "All", text: "All" }].concat(
        _.uniq(incomes.map((c) => c.provider))
          .sort()
          .map((providerName) => ({ key: providerName, text: providerName })),
      ),
    [incomes],
  );
  const onSelectedProviderChange = useCallback(
    (ev: React.FormEvent, option?: IDropdownOption) => {
      option && setSelectedProvider(option.key as string);
    },
    [],
  );
  const columns = useColumns(saveIncome);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Dropdown
          style={{ width: 120 }}
          label="Provider"
          options={providers}
          selectedKey={selectedProvider}
          onChange={onSelectedProviderChange}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="principalByProvider">Principal</label>
          <div id="principalByProvider">{principalValue}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="amountByProvider">Maturity Value</label>
          <div id="amountByProvider">{amountValue}</div>
        </div>
        <IncomeField saveIncome={saveIncome} />
      </div>
      <DetailsList
        checkboxVisibility={CheckboxVisibility.hidden}
        items={filteredIncomes}
        columns={columns}
      />
    </>
  );
};
