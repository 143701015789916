import {
  Checkbox,
  CheckboxVisibility,
  IColumn,
  ShimmeredDetailsList,
} from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { AddEventButton } from "./AddEventButton";
import { useCalendarEvents } from "./UseCalendarEvents";

interface EventsCardProps {
  className?: string;
}

const topBarCss: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const columns: IColumn[] = [
  {
    key: "title",
    fieldName: "title",
    name: "Title",
    minWidth: 140,
  },
  {
    key: "eventType",
    fieldName: "eventType",
    name: "Type",
    minWidth: 80,
  },
  {
    key: "startTime",
    fieldName: "startTime",
    name: "Start time",
    minWidth: 140,
  },
];

export const EventsCard = ({ className }: EventsCardProps) => {
  const [todaysEventsOnly, setTodaysEventsOnly] = useState(true);
  const onChangeTodaysEventsOnly = useCallback(
    (_ev?: React.FormEvent, checked?: boolean) => {
      setTodaysEventsOnly(!!checked);
    },
    []
  );
  const { loadingCalendarEvents, calendarEvents, addCalendarEvent } =
    useCalendarEvents(todaysEventsOnly);

  return (
    <div className={className}>
      <div style={topBarCss}>
        <Checkbox
          label="Show today's events only"
          checked={todaysEventsOnly}
          onChange={onChangeTodaysEventsOnly}
        />
        <AddEventButton onAddEvent={addCalendarEvent} />
      </div>
      <ShimmeredDetailsList
        enableShimmer={loadingCalendarEvents}
        checkboxVisibility={CheckboxVisibility.hidden}
        columns={columns}
        items={calendarEvents}
      />
    </div>
  );
};
