import { Panel, PrimaryButton } from "@fluentui/react";
import { Field, Form, Formik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { DatePickerField, SubmitButton } from "../Shared";
import { IncomeInfo, IncomeType, SaveIncomeCallback } from "./IncomeInfo";
import * as Yup from "yup";

const topBarCss: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const typeOptions: { key: IncomeType; text: string }[] = [
  { key: "Art", text: "Art" },
  { key: "CD", text: "CD" },
  { key: "IncomeNote", text: "IncomeNote" },
  { key: "Legal", text: "Legal" },
  { key: "PrivateCredit", text: "PrivateCredit" },
  { key: "RealEstate", text: "RealEstate" },
  { key: "WineAndWhisky", text: "WineAndWhisky" },
];

const validationSchema = Yup.object().shape({
  incomeName: Yup.string().required(),
  provider: Yup.string().required(),
  principal: Yup.number().moreThan(0).required(),
  amount: Yup.number().moreThan(0).required(),
});

interface IncomeFieldProps {
  income?: IncomeInfo;
  saveIncome: SaveIncomeCallback;
}

export const IncomeField = ({ income, saveIncome }: IncomeFieldProps) => {
  const initialIncome = useMemo<Omit<IncomeInfo, "id">>(
    () =>
      income || {
        incomeName: "",
        principal: 0,
        amount: 0,
        date: new Date(),
        type: "CD",
        provider: "",
      },
    [income],
  );
  const [isOpen, setIsOpen] = useState(false);

  const onOpenPanel = useCallback(() => setIsOpen(true), []);
  const onDismiss = useCallback(() => setIsOpen(false), []);

  const onSaveIncome = useCallback(
    (incomeToSave: Omit<IncomeInfo, "id">) => {
      const id = income ? income.id : -1;
      saveIncome({ ...incomeToSave, id });
      setIsOpen(false);
    },
    [income, saveIncome],
  );
  const operationLabel = income ? "Edit income" : "Add income";

  return (
    <div style={topBarCss}>
      <Panel headerText={operationLabel} isOpen={isOpen} onDismiss={onDismiss}>
        <Formik<Omit<IncomeInfo, "id">>
          initialValues={initialIncome}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSaveIncome}
        >
          <Form style={{ display: "flex", flexDirection: "column" }}>
            <label htmlFor="incomeName">Name</label>
            <Field id="incomeName" name="incomeName" />

            <label htmlFor="provider">Provider</label>
            <Field id="provider" name="provider" />

            <label htmlFor="type">Provider</label>
            <Field id="type" name="type" as="select">
              {typeOptions.map((typeOption) => (
                <option key={typeOption.key} value={typeOption.key}>
                  {typeOption.text}
                </option>
              ))}
            </Field>

            <label htmlFor="date">Date</label>
            <DatePickerField fieldName={"date"} />

            <label htmlFor="principal">Principal</label>
            <Field id="principal" name="principal" type="number" />

            <label htmlFor="amount">Amount</label>
            <Field id="amount" name="amount" type="number" />

            <div style={{ marginTop: 10 }}>
              <SubmitButton />
            </div>
          </Form>
        </Formik>
      </Panel>
      <div></div>
      {income ? (
        <a href="#" onClick={onOpenPanel}>
          {income.incomeName || "<unknown>"}
        </a>
      ) : (
        <PrimaryButton onClick={onOpenPanel}>{operationLabel}</PrimaryButton>
      )}
    </div>
  );
};
