import {
  DatePicker,
  DefaultButton,
  Panel,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { useCallback, useState } from "react";
import { CalendarEvent, RecurrenceType } from "./CalendarEvent";

interface AddEventButtonProps {
  onAddEvent: (event: CalendarEvent) => void;
}

export const AddEventButton = ({ onAddEvent }: AddEventButtonProps) => {
  const [showAddEvenPanel, setShowAddEvenPanel] = useState(false);
  const [title, setTitle] = useState("");
  const [eventType, setEventType] = useState("Birthday");
  const [personName, setPersonName] = useState("");
  const [startTime, setStartTime] = useState("");
  const duration = 86400000;
  const recurrenceType = RecurrenceType.Annual;
  const onChangeTitle = useCallback(
    (_ev: React.FormEvent, newValue?: string) => {
      setTitle(newValue || "");
    },
    []
  );
  const onChangeEventType = useCallback(
    (_ev: React.FormEvent, newValue?: string) => {
      setEventType(newValue || "");
    },
    []
  );
  const onChangePersonName = useCallback(
    (_ev: React.FormEvent, newValue?: string) => {
      setPersonName(newValue || "");
    },
    []
  );
  const onChangeStartTime = useCallback((date: Date | null | undefined) => {
    date && setStartTime(date.toISOString());
  }, []);
  const openAddEventPanel = useCallback(() => {
    setShowAddEvenPanel(true);
  }, []);
  const dismissAddEventPanel = useCallback(() => {
    setShowAddEvenPanel(false);
  }, []);
  const addEventAndDismissPanel = useCallback(async () => {
    await onAddEvent({
      title,
      eventType,
      personName,
      startTime,
      duration,
      recurrenceType,
    });
    setShowAddEvenPanel(false);
  }, [
    onAddEvent,
    title,
    eventType,
    personName,
    startTime,
    duration,
    recurrenceType,
  ]);
  return (
    <>
      <PrimaryButton onClick={openAddEventPanel}>Add event</PrimaryButton>
      <Panel isOpen={showAddEvenPanel} onDismiss={dismissAddEventPanel}>
        <TextField label="Title" value={title} onChange={onChangeTitle} />
        <TextField
          label="Type"
          value={eventType}
          onChange={onChangeEventType}
        />
        <TextField
          label="Person"
          value={personName}
          onChange={onChangePersonName}
        />
        <DatePicker
          label="Start time"
          onSelectDate={onChangeStartTime}
          minDate={new Date("1900-01-01")}
          maxDate={new Date("2100-01-01")}
        />
        <PrimaryButton onClick={addEventAndDismissPanel}>Save</PrimaryButton>
        <DefaultButton onClick={dismissAddEventPanel}>Cancel</DefaultButton>
      </Panel>
    </>
  );
};
