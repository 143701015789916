import { DatePicker } from "@fluentui/react";
import { useField } from "formik";
import { useCallback } from "react";

export const DatePickerField = ({ fieldName }: { fieldName: string }) => {
  const [{ value }, , { setValue }] = useField(fieldName);

  const onDateChange = useCallback(
    (newDate?: Date | null) => {
      newDate && setValue(newDate);
    },
    [setValue],
  );
  return (
    <DatePicker id={fieldName} value={value} onSelectDate={onDateChange} />
  );
};
