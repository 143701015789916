import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { IncomeInfo } from "./IncomeInfo";

const incomeSourceLocalStorageKey = "R86_IncomeSource";
const initialIncomeSource =
  localStorage.getItem(incomeSourceLocalStorageKey) || undefined;
const getNextId = (incomes: IncomeInfo[]) => {
  return (_.max(incomes.map((c) => c.id)) || 0) + 1;
};

export const useIncomes = () => {
  const [incomeSource, setIncomeSource] = useState(initialIncomeSource);
  const [incomes, setIncomes] = useState<IncomeInfo[]>([]);

  useEffect(() => {
    if (incomeSource) {
      fetch(incomeSource)
        .then((response) => {
          if (response.status >= 400) {
            throw response;
          }
          return response.json();
        })
        .then((newIncomes: IncomeInfo[]) => {
          let nextId = getNextId(newIncomes);
          for (const item of newIncomes) {
            if (!item.id) {
              item.id = nextId++;
            }
            item.date = new Date(item.date);
          }
          const today = new Date();
          setIncomes(newIncomes.filter((c) => c.date > today));
        })
        .catch((err) => {
          if (err.status !== 404) {
            alert(err);
          }
        });
    }
  }, [incomeSource]);

  const saveIncomeSource = useCallback((newSource: string) => {
    setIncomeSource(newSource);
    if (newSource) {
      localStorage.setItem(incomeSourceLocalStorageKey, newSource);
    } else {
      localStorage.removeItem(incomeSourceLocalStorageKey);
    }
  }, []);
  const saveIncome = useCallback(
    (incomeToSave: IncomeInfo) => {
      if (!incomeSource) {
        alert("Unable to save income. Please configure income source.");
        return;
      }

      const updatedIndex = incomes.findIndex((c) => c.id === incomeToSave.id);
      const newIncomes = incomes.slice();
      if (updatedIndex >= 0) {
        newIncomes.splice(updatedIndex, 1, incomeToSave);
      } else {
        newIncomes.push({ ...incomeToSave, id: getNextId(incomes) });
      }

      fetch(incomeSource, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-ms-blob-content-type": "application/json",
          "x-ms-blob-type": "BlockBlob",
        },
        body: JSON.stringify(newIncomes),
      })
        .then((response) => {
          if (response.status >= 400) {
            return response.text().then((text) => {
              throw text;
            });
          }

          setIncomes(newIncomes);
        })
        .catch((err) => {
          alert(err);
        });
    },
    [incomeSource, incomes],
  );

  return {
    incomeSource,
    saveIncomeSource,
    incomes,
    saveIncome,
  };
};
