import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { usDollarFormat } from "./Formats";
import { IncomeInfo } from "./IncomeInfo";

const today = new Date();
const chartDateFormat = new Intl.DateTimeFormat("en-US", {
  month: "short",
  year: "2-digit",
});
const labelFormatter = (label: Date) => <>{chartDateFormat.format(label)}</>;
const tooltipFormatter = (value: number) => <>{usDollarFormat.format(value)}</>;

type Range = "1M" | "3M" | "6M" | "1Y";
const choices: IChoiceGroupOption[] = [
  { key: "1M", text: "Monthly" },
  { key: "3M", text: "Quarterly" },
  { key: "6M", text: "Half-yearly" },
  { key: "1Y", text: "Annual" },
];

interface IncomeTimelineChartProps {
  incomes: IncomeInfo[];
}

export const IncomeTimelineChart = ({ incomes }: IncomeTimelineChartProps) => {
  const [range, setRange] = useState<Range>("3M");
  const chartData = useMemo(
    () => getChartData(incomes, range),
    [incomes, range],
  );

  const onGroupChange = useCallback(
    (_?: React.FormEvent, option?: IChoiceGroupOption) => {
      option && setRange(option.key as Range);
    },
    [],
  );

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <ChoiceGroup
        options={choices}
        selectedKey={range}
        onChange={onGroupChange}
      />
      <LineChart data={chartData} width={580} height={160}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={chartDateFormat.format} />
        <YAxis tickFormatter={usDollarFormat.format} width={80} />
        <Tooltip labelFormatter={labelFormatter} formatter={tooltipFormatter} />
        <Line type="monotone" dataKey="amount" />
      </LineChart>
    </div>
  );
};

function getChartData(incomes: IncomeInfo[], range: Range) {
  const yearMonthMap: Record<
    number | string,
    Record<number | string, number>
  > = {};

  for (const income of incomes) {
    if (income.date < today) {
      continue;
    }

    const year = income.date.getFullYear();
    const month = income.date.getMonth();
    let groupedMonth: number;
    switch (range) {
      case "1M":
        yearMonthMap[year] = yearMonthMap[year] || {
          0: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
        };
        groupedMonth = month;
        break;
      case "6M":
        yearMonthMap[year] = yearMonthMap[year] || { 5: 0, 11: 0 };
        groupedMonth = month - (month % 6) + 5;
        break;
      case "1Y":
        yearMonthMap[year] = yearMonthMap[year] || { 11: 0 };
        groupedMonth = 11;
        break;
      case "3M":
      default:
        yearMonthMap[year] = yearMonthMap[year] || { 2: 0, 5: 0, 8: 0, 11: 0 };
        groupedMonth = month - (month % 3) + 2;
        break;
    }

    yearMonthMap[year][groupedMonth] =
      (yearMonthMap[year][groupedMonth] || 0) + income.amount;
  }

  const chartItems: { amount: number; date: Date }[] = [];

  for (const year in yearMonthMap) {
    const monthMap = yearMonthMap[year];
    for (const month in monthMap) {
      chartItems.push({
        amount: monthMap[month],
        date: new Date(parseInt(year), parseInt(month), 1),
      });
    }
  }

  return _.sortBy(chartItems, (item) => item.date);
}
