import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Cell, Pie, PieChart, PieLabel, Tooltip } from "recharts";
import { usDollarFormat } from "./Formats";
import { IncomeInfo } from "./IncomeInfo";

const today = new Date();
const COLORS = [
  "#8BC1F7",
  "#BDE2B9",
  "#A2D9D9",
  "#B2B0EA",
  "#F9E0A2",
  "#F4B678",
  "#C9190B",
  "#D2D2D2",
];
const tooltipFormatter = (value: number) => <>{usDollarFormat.format(value)}</>;
const choices: IChoiceGroupOption[] = [
  { key: "Provider", text: "Provider" },
  { key: "Type", text: "Type" },
];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel: PieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

type Slice = "Type" | "Provider";

interface IncomeBySlicePieChartProps {
  incomes: IncomeInfo[];
}

export const IncomeBySlicePieChart = ({
  incomes,
}: IncomeBySlicePieChartProps) => {
  const [slice, setSlice] = useState<Slice>("Provider");
  const onSliceChange = useCallback(
    (_?: React.FormEvent, option?: IChoiceGroupOption) => {
      option && setSlice(option.key as Slice);
    },
    [],
  );
  const principalValue = useMemo(() => {
    return usDollarFormat.format(
      incomes.reduce((total, current) => total + current.principal, 0),
    );
  }, [incomes]);
  const amountValue = useMemo(() => {
    return usDollarFormat.format(
      incomes.reduce((total, current) => total + current.amount, 0),
    );
  }, [incomes]);
  const principalData = usePieChartData(incomes, slice, (c) => c.principal);
  const amountData = usePieChartData(incomes, slice, (c) => c.amount);
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <ChoiceGroup
        options={choices}
        selectedKey={slice}
        onChange={onSliceChange}
      />
      <div>
        <h4
          style={{ margin: "12px 0 0" }}
        >{`Principal (${principalValue})`}</h4>
        <PieChart width={290} height={240}>
          <Pie
            data={principalData}
            dataKey="value"
            label={renderCustomizedLabel}
            labelLine={false}
          >
            {principalData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                format={"test"}
              />
            ))}
          </Pie>
          <Tooltip formatter={tooltipFormatter} />
        </PieChart>
      </div>
      <div>
        <h4
          style={{ margin: "12px 0 0" }}
        >{`Maturity value (${amountValue})`}</h4>
        <PieChart width={290} height={240}>
          <Pie
            data={amountData}
            dataKey="value"
            label={renderCustomizedLabel}
            labelLine={false}
          >
            {amountData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                format={"test"}
              />
            ))}
          </Pie>
          <Tooltip formatter={tooltipFormatter} />
        </PieChart>
      </div>
    </div>
  );
};

function usePieChartData(
  incomes: IncomeInfo[],
  slice: string,
  callback: (income: IncomeInfo) => number,
) {
  return useMemo(() => {
    const sliceMap: Record<string, number> = {};
    for (const income of incomes) {
      if (income.date < today) {
        continue;
      }

      const sliceName = slice === "Provider" ? income.provider : income.type;
      sliceMap[sliceName] = (sliceMap[sliceName] || 0) + callback(income);
    }

    return _.reverse(
      _.sortBy(
        Object.keys(sliceMap).map((c) => ({ name: c, value: sliceMap[c] })),
        (c) => c.value,
      ),
    );
  }, [incomes, slice, callback]);
}
