export const enum RecurrenceType {
  None = 0,
  Annual = 1,
}

export interface CalendarEvent {
  title: string;
  personName: string;
  eventType: string;
  startTime: string;
  duration: number;
  recurrenceType: RecurrenceType;
}
